import { Link } from 'gatsby';
import React from 'react';

const posts = [
  {
    title: 'Easiest way to set up Google Tag Manager in Shopify',
    href: '/google-tag-manager-shopify',
    description:
      'If you’ve worked in digital marketing or launched an online campaign, chances are you’ve worked with marketing tags (or pixels). A marketing tag is an element that is included in webpages or platforms that you want to measure.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
  },
];

const RecentBlogPosts = () => {
  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-3xl">
        <div>
          <h2 className="text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Recent blog posts
          </h2>
        </div>
        <div className="mt-6 pt-10">
          {posts.map((post) => (
            <div key={post.title}>
              {/* <p className="text-sm text-gray-500">
                <time dateTime={post.datetime}>{post.date}</time>
              </p> */}
              <Link to={post.href} className="mt-2 block">
                <p className="text-xl font-semibold text-gray-900">
                  {post.title}
                </p>
                <p className="mt-3 text-base text-gray-500">
                  {post.description}
                </p>
              </Link>
              <div className="mt-3">
                <Link
                  to={post.href}
                  className="text-base font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Read full story
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentBlogPosts;

import React from 'react';
import Icons from '../icons';

const renderIcon = (icon, className) => {
  switch (icon) {
    case 'list_view':
      return <Icons.ListView className={className} />;
    case 'code':
      return <Icons.Code className={className} />;
    case 'user_circle':
      return <Icons.UserCircle className={className} />;
    case 'shopping_cart':
      return <Icons.ShoppingCart className={className} />;
    case 'cube':
      return <Icons.Cube className={className} />;
    case 'transparent_cube':
      return <Icons.TransparentCube className={className} />;
    case 'cursor_click':
      return <Icons.CursorClick className={className} />;
    case 'currency_dollar':
      return <Icons.CurrencyDollar className={className} />;
    case 'user_group':
      return <Icons.UserGroup className={className} />;
    case 'presentation_chart_bar':
      return <Icons.PresentationChartBar className={className} />;
    default:
      return <Icons.Code className={className} />;
  }
};

const ListItem = ({ title, description, icon }) => (
  <div className="flex">
    <div className="flex-shrink-0 h-12 w-12 border-2 border-gray-200 from-green-600 to-green-400 rounded-md flex items-center justify-center">
      {/* <Icons.ListView className="h-6 w-6 text-white" /> */}
      {renderIcon(icon, 'h-6 w-6 text-indigo-600')}
    </div>
    <div className="ml-4">
      <dt className="text-lg leading-6 font-medium text-gray-900">{title}</dt>
      <dd className="mt-2 text-base text-gray-500">{description}</dd>
    </div>
  </div>
);
// const ListItemFilled = ({ title, description, icon }) => (
//   <div className="flex">
//     <div className="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-green-600 to-green-400 rounded-md flex items-center justify-center">
//       {/* <Icons.ListView className="h-6 w-6 text-white" /> */}
//       {renderIcon(icon, 'h-6 w-6 text-white')}
//     </div>
//     <div className="ml-4">
//       <dt className="text-lg leading-6 font-medium text-gray-900">{title}</dt>
//       <dd className="mt-2 text-base text-gray-500">{description}</dd>
//     </div>
//   </div>
// );

export default ListItem;

import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Testimonial = ({ name, title, quote, logo, photo }) => (
  <section className="py-12 overflow-hidden md:py-20 lg:py-24">
    <div className="relative px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
      <svg
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
        role="img"
        aria-labelledby="svg"
        className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
      >
        <defs>
          <pattern
            id="ad119f34-7694-4c31-947f-5c9d249b21f3"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              fill="currentColor"
              className="text-gray-200"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="404"
          fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
        />
      </svg>
      <div className="relative">
        <div className="h-16 mx-auto text-center">
          <Img fixed={logo.childImageSharp.fixed} alt={logo.name} />
        </div>
        <blockquote className="mt-8">
          <div className="max-w-3xl mx-auto text-2xl font-medium text-center text-gray-900 leading-9">
            <p>{`“${quote}”`}</p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              <div className="md:flex-shrink-0 text-center leading-zero">
                {/* <img
                  src="https://images.unsplash.com/photo-1573007974656-b958089e9f7b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
                  alt=""
                  className="w-10 h-10 mx-auto rounded-full object-cover"
                /> */}
                <Img
                  fixed={photo.childImageSharp.fixed}
                  alt={photo.name}
                  title={photo.name}
                  imgStyle={{
                    borderRadius: '9999px',
                    width: 40,
                    height: 40,
                  }}
                  style={{
                    width: 40,
                    height: 40,
                  }}
                />
              </div>
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base font-medium text-gray-900 leading-6">
                  {name}
                </div>
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className="hidden w-5 h-5 mx-1 text-indigo-600 md:block"
                >
                  <path d="M11 0h3L9 20H6l5-20z" />
                </svg>
                <div className="text-base font-medium text-gray-500 leading-6">
                  {title}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
);

Testimonial.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  quote: PropTypes.string,
  logo: PropTypes.object.isRequired,
  photo: PropTypes.object.isRequired,
};

Testimonial.defaultProps = {
  name: 'Adam Smith',
  title: 'CEO, Founder',
  quote:
    'EasyTag is awesome! I dont need to worry about setting up my tags properly, and can focus on selling more stuff.',
};

export default Testimonial;

import React from 'react';
import PropTypes from 'prop-types';

const SimpleJustified = ({
  title,
  subtitle,
  primaryActionLabel,
  primaryActionURL,
  secondaryActionLabel,
  secondaryActionURL,
}) => (
  <div className="bg-gradient-to-r from-blue-600 to-blue-400">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 className="text-3xl font-extrabold tracking-tight text-blue-300 sm:text-4xl">
        <span className="block">{title}</span>
        {subtitle && <span className="block text-white mt-3">{subtitle}</span>}
      </h2>
      <div className="mt-8 lex lg:mt-0 lg:flex-shrink-0">
        <div className="inline-flex rounded-md shadow">
          <a
            href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-100"
          >
            {primaryActionLabel}
          </a>
        </div>
        <div className="ml-6 inline-flex rounded-md">
          <a
            href="mailto:support@taginstall.com?subject=Easy%20Tag%20demo"
            className="whitespace-nowrap text-base font-medium text-blue-100 hover:text-blue-50"
          >
            {secondaryActionLabel}
          </a>
        </div>
      </div>
    </div>
  </div>
);

SimpleJustified.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  primaryActionLabel: PropTypes.string,
  primaryActionURL: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
  secondaryActionURL: PropTypes.string,
};
SimpleJustified.defaultProps = {
  title: 'Ready to dive in?',
  subtitle: 'Install Easy Tag today.',
  primaryActionLabel: 'Install the app',
  primaryActionURL:
    'https://apps.shopify.com/google-tag-manager-installer?surface_type=website',
  secondaryActionLabel: 'Book a demo',
  secondaryActionURL: 'mailto:hello@taginstall.com',
};

export default SimpleJustified;

import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import gif from '../images/Google_Analytics_new_reporting.gif';
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import AnimatedCTA from '../components/AnimatedCTA';
import TwoColumns from '../components/TwoColumns';
import CTAs from '../components/CTAs';
import Testimonial from '../components/Testimonial';
import Integrations from '../components/Integrations';
import TechnicalSupport from '../components/TechnicalSupport';
import RecentBlogPosts from '../components/RecentBlogs';

// const step1Items = [
//   {
//     title: 'Add Easy Tag to your Shopify store',
//     description: `As with all Shopify apps, the installation process is as simple as it could be. On top of this, we have an easy onbording flow to help you complete the requred steps.`,
//     icon: 'shopping_cart',
//   },
//   {
//     title: 'Connect your Google account',
//     description: `You have to connect a Google account, which has access to your Google Tag Manager container. This will allow you to import our ready-made marketing tags (including Google Analytics 4) to your GTM container.`,
//     icon: 'user_circle',
//   },
//   {
//     title: 'Install GTM snippets',
//     description: `This is a 1-Click installation process, which will install Google Tag Manager snippets in your Shopify theme.`,
//     icon: 'code',
//   },
// ];

// const step2Items = [
//   {
//     title: 'Product events',
//     description: `You will be able to track Product Detail Pages, Collection Pages and Search Results with detailed product information like Product ID, Product Title, Price and more.`,
//     icon: 'cube',
//   },
//   {
//     title: 'Cart events',
//     description: `Purus lobortis volutpat posuere id integer nunc tellus. Non mauris
//     malesuada feugiat massa mi pellentesque cum est. Pharetra a varius
//     urna rhoncus, tempor rutrum.`,
//     icon: 'shopping_cart',
//   },
//   {
//     title: 'Conversions',
//     description: `Conversion tracking to Google Analytycs 4 (GA4) is happening, when a customer visits the order "Thank You" page for the first time.`,
//     icon: 'currency_dollar',
//   },
//   {
//     title: 'Custom events',
//     description:
//       'With the help of our robust Google Tag Manager data layer and a library of more than 100 GTM variables you can easily track everything by creating your own custom tags.',
//     icon: 'cursor_click',
//   },
// ];
// const step3Items = [
//   {
//     title: 'Customer-centric Measurement',
//     description: `According to Google, GA4 will give you "A more complete understanding of how customers interact with your business".`,
//     icon: 'user_group',
//   },
//   {
//     title: 'Smarter Insights',
//     description: `With the help of advanced machine learning models, the new Google Analytics 4 can automatically alert you to significant trends in your data.`,
//     icon: 'transparent_cube',
//   },
//   {
//     title: 'Enhanced Reporting',
//     description: `You will a range of new visualisations and reporting functions in your GA4 property.`,
//     icon: 'presentation_chart_bar',
//   },
//   {
//     title: 'BigQuery Export',
//     description: `You will be able to export all of your raw events from Google Analytics 4 to BigQuery, and then use that data to get even more insights.`,
//     icon: 'list_view',
//   },
// ];

// const gridItems = [
//   {
//     label: 'view_item',
//   },
//   {
//     label: 'view_item_list',
//   },
//   {
//     label: 'add_to_cart',
//   },
//   {
//     label: 'remove_from_cart',
//   },
//   {
//     label: 'purchase',
//   },
//   {
//     label: 'custom_event',
//   },
// ];

const ga4Events = [
  {
    id: 1,
    label: 'Product View',
    color: 'blue',
  },
  {
    id: 2,
    label: 'Collection View',
    color: 'blue',
  },
  {
    id: 3,
    label: 'Add to Cart',
    color: 'orange',
  },
  {
    id: 4,
    label: 'Remove from Cart',
    color: 'orange',
  },
  {
    id: 5,
    label: 'Purchase',
    color: 'teal',
  },
  {
    id: 6,
    label: 'Custom event',
    color: 'purple',
  },
];

const LandingPage = ({ data }) => (
  <Layout>
    <Seo
      title="Google Analytics 4 for Shopify"
      description="Simple 1-Click Google Analytics 4 (GA4) installation for Shopify, Google Tag Manager data layer, and 60+ ready-made marketing tags. Easy."
    />
    <AnimatedCTA title1="Google Analytics 4 for Shopify" items={ga4Events} />
    {/* <ImageSection
      // title="Get more information from your customers"
      image={data.imgShopifyGA4Insights}
    /> */}
    <TwoColumns
      title="Integrate Google Analytics 4 with a few clicks. Simple."
      image1={data.imgEasyTagOverview}
      image2={data.imgGA4Events}
      image3={gif}
    />
    {/* <Headers.SimpleHeader
      title="Google Analytics 4 for Shopify"
      heading="Bring GA4 to your Shopify store"
      subheading="Keep up with the latest Google trends and get the most out of your e-commerce analytics data"
    /> */}
    {/* <Steps
      image1={data.imgInstallEasyTag}
      image2={data.imgImportGA4}
      image3={data.imgGA4Insights}
    /> */}
    {/* <SplitBrandPanel right>
      <ListPanel
        title="Install our Data Layer"
        heading="Get more information from your customers"
        items={step1Items}
        paddingRight
      />
      <ImagePanel image={data.imgEasyTagOverview} />
    </SplitBrandPanel>
    <SplitBrandPanel>
      <GridPanel items={gridItems} left heading="Supported E-commerce events" />
      <ListPanel
        title="Import GA4"
        heading="Set up in minutes"
        items={step2Items}
      />
    </SplitBrandPanel>
    <SplitBrandPanel right>
      <ListPanel
        title="Get insights"
        heading="Learn more about how customers interact with your business"
        items={step3Items}
        paddingRight
      />
      <ImagePanel image={gif} animatedGif />
    </SplitBrandPanel> */}
    <CTAs.SimpleJustified
      subtitle="Start using Easy Tag today."
      primaryActionLabel="Get Started"
    />
    <Testimonial
      name="Sam Franklin"
      logo={data.imgOriginalKettlebell}
      quote="Easy Tag saved me days! My data is clean, my tags are firing correctly, and I can focus on selling."
      photo={data.imgSamFranklin}
    />
    <Integrations primaryActionLabel="Get Started" />
    <TechnicalSupport image={data.imgBenefitFriendlyTechnicalSupport} />

    <CTAs.Big image={data.imgTagInstallHero} primaryActionLabel="Get Started" />
    <RecentBlogPosts />
    {/* <CTAs.CTAGradient /> */}
  </Layout>
);

LandingPage.propTypes = {
  data: PropTypes.shape({
    imgTagInstallHero: PropTypes.object.isRequired,
    imgOriginalKettlebell: PropTypes.object.isRequired,
    imgBenefitFriendlyTechnicalSupport: PropTypes.object.isRequired,
    imgSamFranklin: PropTypes.object.isRequired,
    imgEasyTagOverview: PropTypes.object.isRequired,
    imgGA4Events: PropTypes.object.isRequired,
  }).isRequired,
};

export default LandingPage;

export const data = graphql`
  query LandingPageQuery {
    imgTagInstallHero: file(name: { eq: "taginstall-hero-image" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgShopifyGA4Insights: file(name: { eq: "shopify-ga4-insights" }) {
      name
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgGA4Events: file(name: { eq: "ga4-events" }) {
      name
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgOriginalKettlebell: file(name: { eq: "original-kettlebell" }) {
      name
      childImageSharp {
        fixed(width: 140, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgInstallEasyTag: file(name: { eq: "install-easy-tag-in-shopify" }) {
      name
      childImageSharp {
        fixed(width: 180, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgGA4Insights: file(name: { eq: "get-ga4-insights" }) {
      name
      childImageSharp {
        fixed(width: 180, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgImportGA4: file(name: { eq: "import-ga4-in-gtm" }) {
      name
      childImageSharp {
        fixed(width: 180, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgEasyTagOverview: file(name: { eq: "easy-tag-overview-in-shopify" }) {
      name
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgBenefitFriendlyTechnicalSupport: file(
      name: { eq: "benefit-friendly-technical-support" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgSamFranklin: file(name: { eq: "sam-franklin" }) {
      name
      childImageSharp {
        fixed(height: 40, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Item = ({
  label,
  color,
  triggerAnimation,
  setCurrentLabel,
  setCurrentColor,
}) => {
  const [checked, setChecked] = useState(false);
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={`mb-3 flex justify-center items-center p-3 rounded-md hover:bg-coolGray-200 ${
        checked ? 'bg-blue-100' : 'bg-coolGray-100'
      }`}
      data-children-count="1"
    >
      <input
        onChange={(e) => {
          setChecked(e.target.checked);
          if (e.target.checked) {
            setCurrentLabel(label);
            setCurrentColor(color);
            triggerAnimation();
          }
        }}
        type="checkbox"
        className={`rounded border-gray-300 text-${
          color || 'indigo'
        }-400 shadow-sm focus:border-${
          color || 'indigo'
        }-300 focus:ring focus:ring-${
          color || 'indigo'
        }-200 focus:ring-opacity-50`}
      />
      <span className="ml-2 text-black leading-zero">{label}</span>
    </label>
  );
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  triggerAnimation: PropTypes.func.isRequired,
  setCurrentLabel: PropTypes.func.isRequired,
  setCurrentColor: PropTypes.func.isRequired,
};

const AnimatedTest = ({ title1, title2, subtitle, items }) => {
  const [currentLabel, setCurrentLabel] = useState('Event');
  const [currentColor, setCurrentColor] = useState('indigo');
  const [triggerButtonAnimation, setTriggerButtonAnimation] = useState(false);
  const [triggerAddAnimation, setTriggerAddAnimation] = useState(false);
  return (
    <div className="">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-black sm:text-4xl">
          {title1 && <span className="block">{title1}</span>}
          {title2 && <span className="block">{title2}</span>}
        </h1>
        <p className="mt-4 text-lg leading-6 text-black">{subtitle}</p>
        <div className="mt-10 flex flex-row flex-wrap justify-around">
          {/* <input type="checkbox" className="rounded text-pink-500" /> */}
          {items.map((item) => (
            <Item
              key={item.id}
              label={item.label}
              color={item.color}
              triggerAnimation={() => setTriggerAddAnimation(true)}
              setCurrentLabel={setCurrentLabel}
              setCurrentColor={setCurrentColor}
            />
          ))}
        </div>
        <div className="relative w-full h-32 overflow-hidden">
          <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center">
            <a
              href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
              className={`${
                triggerButtonAnimation && 'animate-scale'
              } w-2/3 inline-flex items-center justify-center px-10 py-5 border border-transparent text-base font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-400 sm:w-auto`}
              onAnimationEnd={() => {
                setTriggerButtonAnimation(false);
                setTriggerAddAnimation(false);
              }}
            >
              Get Started
            </a>
          </div>
          <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center -z-10">
            <div
              onAnimationEnd={() => setTriggerButtonAnimation(true)}
              className={`${
                triggerAddAnimation && 'animate-jump'
              } px-2 py-1 bg-${currentColor}-500 text-white rounded`}
            >
              {currentLabel}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AnimatedTest.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.array.isRequired,
};
AnimatedTest.defaultProps = {
  title1: 'Google Analytics 4 for Shopify',
  title2: '',
  subtitle: 'What do you want to track with Google Analytics 4?',
};

export default AnimatedTest;

import ListView from './ListView';
import Code from './Code';
import UserCircle from './UserCircle';
import ShoppingCart from './ShopingCart';
import Cube from './Cube';
import CurrencyDollar from './CurrencyDollar';
import CursorClick from './CursorClick';
import UserGroup from './UserGroup';
import PresentationChartBar from './PresentationChartBar';
import TransparentCube from './TransparentCube';

export default {
  ListView,
  Code,
  UserCircle,
  ShoppingCart,
  Cube,
  CursorClick,
  CurrencyDollar,
  UserGroup,
  PresentationChartBar,
  TransparentCube,
};

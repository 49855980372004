import React from 'react';
import PropTypes from 'prop-types';

const CTAGradient = ({ title, heading, subheading }) => (
  <div className="bg-gradient-to-r from-indigo-600 to-indigo-400">
    <div className="max-w-2xl mx-auto py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
        <span className="block">Boost your productivity.</span>
        <span className="block text-indigo-900">
          Start using Workflow today.
        </span>
      </h2>
      <p className="mt-4 text-lg leading-6 text-indigo-100">
        Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
        Malesuada adipiscing sagittis vel nulla nec.
      </p>
      <a
        href="/"
        className="mt-8 w-full bg-indigo-50 border border-transparent rounded-md py-3 px-5 inline-flex items-center justify-center text-base font-medium text-indigo-700 hover:bg-indigo-100 sm:w-auto"
      >
        Sign up for free
      </a>
    </div>
  </div>
);

CTAGradient.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};
CTAGradient.defaultProps = {
  title: 'Pricing',
  heading: 'Take control of your team.',
  subheading:
    'Start building for free, then add a site plan to go live. Account plans unlock additional features.',
};

export default CTAGradient;

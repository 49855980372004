import React from 'react';
import Img from 'gatsby-image';

const TechnicalSupport = ({ image }) => (
  <div className="bg-gray-100">
    <div className="max-w-3xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="w-full flex flex-col lg:flex-row text-black">
        <div className="w-full lg:w-1/3 flex flex-row lg:flex-col justify-center pr-8">
          <Img
            fluid={image.childImageSharp.fluid}
            alt={image.name}
            className="rounded-full"
            style={{ width: '200px', height: '200px' }}
          />
        </div>
        <div className="w-full lg:w-2/3 flex flex-col justify-center pr-8 mt-8 lg:mt-0">
          <h3 className="text-base font-bold tracking-wide text-indigo-600 uppercase">
            Friendly technical support
          </h3>
          <p className="mt-2 text-2xl font-extrabold text-gray-900 sm:text-3xl">
            We're here to help
          </p>
          <p className="mt-3 text-lg text-gray-500">
            Our team of tag experts is here to help you and ensure you get the
            most of your tag manager implementation. Don't be a stranger.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default TechnicalSupport;

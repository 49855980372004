import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { StaticImage } from 'gatsby-plugin-image';

import StarRatings from '../StarRatings';

const BigEasyTag = ({ image }) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="bg-gradient-to-r from-blue-600 to-blue-400 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div className="lg:self-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              {/* <span className="block">Ready to dive in?</span> */}
              <span className="block">Try Easy Tag today.</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-indigo-200">
              Pre-built Data Layer for Google Tag Manager &amp; 60+ marketing
              tags.
            </p>
            <StarRatings
              inverse
              className="mt-5"
              linkColor="text-gray-100 hover:text-gray-200"
            />
            <a
              href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
              className="mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
            >
              Get Started
            </a>
          </div>
        </div>
        <div className="aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          {/* <img
            className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
            src="https://tailwindui.com/img/component-images/full-width-with-sidebar.jpg"
            alt="App screenshot"
          /> */}
          {/* <Img
            fluid={image.childImageSharp.fluid}
            alt={image.name}
            className="transform translate-x-6 translate-y-6 rounded-md object-contain object-left-top sm:translate-x-16 lg:translate-y-20"
          /> */}
          <StaticImage
            src="../../images/taginstall-hero-image.png"
            alt="A dinosaur"
            className="transform translate-x-6 translate-y-6 rounded-md object-contain object-left-top sm:translate-x-16 lg:translate-y-20"
          />
        </div>
      </div>
    </div>
  </div>
);

BigEasyTag.propTypes = {
  image: PropTypes.object.isRequired,
};

export default BigEasyTag;

import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ListItem from './Panels/ListItem';

const step2Items = [
  {
    id: 1,
    title: 'Product events',
    description: `You will be able to track Product Detail Pages, Collection Pages and Search Results with detailed product information like Product ID, Product Title, Price and more.`,
    icon: 'cube',
  },
  {
    id: 2,
    title: 'Cart events',
    description: `All cart events like "Add to Cart" and "Remove from Cart" are tracked.`,
    icon: 'shopping_cart',
  },
  {
    id: 3,
    title: 'Conversions',
    description: `Conversion tracking to Google Analytycs 4 (GA4) is happening, when a customer visits the order "Thank You" page for the first time.`,
    icon: 'currency_dollar',
  },
  {
    id: 4,
    title: 'Custom events',
    description:
      'With the help of our robust Google Tag Manager data layer and a library of more than 100 GTM variables you can easily track everything by creating your own custom tags.',
    icon: 'cursor_click',
  },
];

const step3Items = [
  {
    id: 1,
    title: 'Customer-centric Measurement',
    description: `According to Google, GA4 will give you "A more complete understanding of how customers interact with your business".`,
    icon: 'user_group',
  },
  {
    id: 2,
    title: 'Smarter Insights',
    description: `With the help of advanced machine learning models, the new Google Analytics 4 can automatically alert you to significant trends in your data.`,
    icon: 'transparent_cube',
  },
  {
    id: 3,
    title: 'Enhanced Reporting',
    description: `You will a range of new visualisations and reporting functions in your GA4 property.`,
    icon: 'presentation_chart_bar',
  },
  {
    id: 4,
    title: 'BigQuery Export',
    description: `You will be able to export all of your raw events from Google Analytics 4 to BigQuery, and then use that data to get even more insights.`,
    icon: 'list_view',
  },
];

const renderImage = (image) =>
  image.childImageSharp.fixed ? (
    <Img
      fixed={image.childImageSharp.fixed}
      alt={image.name}
      className="w-full"
    />
  ) : (
    <Img
      fluid={image.childImageSharp.fluid}
      alt={image.name}
      className="w-full"
    />
  );

const TwoColumns = ({ title, image1, image2, image3 }) => (
  <div className="bg-gray-100">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      {title && (
        <h2 className="text-3xl font-extrabold text-black text-center pb-16">
          {title}
        </h2>
      )}
      <div className="w-full flex flex-col-reverse text-black my-16 pb-16 lg:flex-row">
        <div className="w-full lg:w-1/2 flex flex-col justify-center pr-8 mt-8 lg:mt-0">
          <h3 className="text-base font-bold tracking-wide text-indigo-600 uppercase">
            Set up in munutes
          </h3>
          <p className="mt-2 text-2xl font-extrabold text-gray-900 sm:text-3xl">
            Install our Data Layer
          </p>
          <p className="mt-3 text-lg text-gray-500">
            The first step is to install{' '}
            <a
              className="text-indigo-600 hover:text-indigo-400"
              href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
              target="_blank"
              rel="noreferrer noopener"
            >
              Easy Tag - GTM &amp; Data Layer
            </a>{' '}
            app. As with all Shopify apps, the installation process is as simple
            as it could be. On top of this, we have an easy onbording flow to
            help you complete the requred steps.
          </p>
        </div>
        <div className="w-full lg:w-1/2">{renderImage(image1)}</div>
      </div>
      <div className="w-full flex flex-col lg:flex-row text-black my-16 py-16">
        <div className="w-full lg:w-1/2 flex flex-col justify-center">
          {renderImage(image2)}
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-center pl-8">
          <h3 className="text-base font-bold tracking-wide text-indigo-600 uppercase">
            Import GA4
          </h3>
          <p className="mt-2 text-2xl font-extrabold text-gray-900 sm:text-3xl">
            All GA4 E-commerce events you need
          </p>
          <dl className="mt-12 space-y-10">
            {step2Items.map(({ title: itemTitle, description, icon, id }) => (
              <ListItem
                key={id}
                title={itemTitle}
                description={description}
                icon={icon}
              />
            ))}
          </dl>
        </div>
      </div>
      <div className="w-full flex flex-col-reverse lg:flex-row text-black my-16 pt-16">
        <div className="w-full lg:w-1/2 flex flex-col justify-center pr-8 mt-8 lg:mt-0">
          <h3 className="text-base font-bold tracking-wide text-indigo-600 uppercase">
            Get insights
          </h3>
          <p className="mt-2 text-2xl font-extrabold text-gray-900 sm:text-3xl">
            Understand your customers
          </p>
          <dl className="mt-12 space-y-10">
            {step3Items.map(({ title: itemTitle, description, icon, id }) => (
              <ListItem
                key={id}
                title={itemTitle}
                description={description}
                icon={icon}
              />
            ))}
          </dl>
        </div>
        <div className="w-full lg:w-1/2 flex items-center bg-gradient-to-r from-blue-600 to-blue-400 rounded-md px-8 py-16">
          <img src={image3} alt="GA4 insights" />{' '}
        </div>
      </div>
    </div>
  </div>
);

TwoColumns.propTypes = {
  title: PropTypes.string.isRequired,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  image3: PropTypes.object.isRequired,
};

export default TwoColumns;

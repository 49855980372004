import React from 'react';
import PropTypes from 'prop-types';
// import Img from 'gatsby-image';

const Integrations = ({ primaryActionLabel }) => (
  <section className="py-8">
    <div className="bg-white">
      <div className="px-4 py-12 mx-auto max-w-screen-xl sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900 leading-9 sm:text-4xl sm:leading-10">
              All the tags you need for your{' '}
              <span className="text-indigo-600">Shopify</span> store.
            </h2>{' '}
            <div className="max-w-3xl mt-3 text-lg text-gray-500 leading-7">
              Simple one-click installation for 50+ ready-made marketing tags.
              Find your Pixel ID, click the checkbox, hit "Install", and you're
              done.
            </div>{' '}
            {/* <div className="mx-auto text-gray-500 prose prose-lg">
              <ul>
                <li>
                  Attach Stripe plan names, and create slices to view feedback
                  from users on each plan
                </li>{' '}
                <li>
                  Create experiment IDs using Optimizely, and create slices to
                  view how your experiments influence feedback
                </li>{' '}
                <li>
                  Use your own javascript to create events on your page. Attach
                  these events to your feedback to slice by key metrics.
                </li>
              </ul>
            </div>{' '} */}
            <div className="mt-8 sm:flex">
              {/* <div className="shadow rounded-md">
                <a
                  href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
                  className="flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent leading-6 rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Install the app
                </a>
              </div>{' '} */}
              {/* <div className="mt-3 sm:mt-0 sm:ml-3"> */}
              <div className="mt-3 sm:mt-0 sm:ml-0">
                <a
                  href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
                  className="flex items-center justify-center px-5 py-3 text-base font-medium text-indigo-700 bg-indigo-100 border border-transparent leading-6 rounded-md hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {primaryActionLabel}
                </a>
              </div>
            </div>{' '}
            <div className="mt-2 text-gray-400">
              Don't see the tag you need, no problem, just ask.
            </div>
          </div>{' '}
          <div className="mt-8 grid grid-cols-3 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-3">
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/facebook.svg"
                alt="Facebook"
                className="max-h-8 filter-gray"
                width={32}
                height={32}
              />
            </div>{' '}
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/google-ads.svg"
                alt="Google Ads"
                className="max-h-8 filter-gray"
                width={35}
                height={32}
              />
            </div>{' '}
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/google-analytics.svg"
                alt="Google Analytics"
                className="max-h-8 filter-gray"
                width={29}
                height={32}
              />
            </div>{' '}
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/klaviyo.svg"
                alt="Klaviyo"
                className="max-h-8 filter-gray"
                width={47}
                height={32}
              />
            </div>{' '}
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/hotjar.svg"
                alt="Hotjar"
                className="max-h-8 filter-gray"
                width={28}
                height={32}
              />
            </div>{' '}
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/twitter.svg"
                alt="Twitter"
                className="filter-gray max-h-8"
                width={32}
                height={32}
              />
            </div>
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/pinterest.svg"
                alt="Pinterest"
                className="filter-gray max-h-8"
                width={32}
                height={32}
              />
            </div>
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/snapchat.svg"
                alt="Snapchat"
                className="filter-gray max-h-8"
                width={32}
                height={32}
              />
            </div>
            <div className="flex justify-center px-8 py-8 col-span-1 bg-gray-50">
              <img
                src="/logos/linkedin.svg"
                alt="LinkedIn"
                className="filter-gray max-h-8"
                width={32}
                height={32}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

Integrations.propTypes = {
  primaryActionLabel: PropTypes.string,
};

Integrations.defaultProps = {
  primaryActionLabel: 'Install the app',
};

export default Integrations;

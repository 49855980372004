import React from 'react';

const StarRatings = ({ className, inverse, linkColor, separatorColor }) => (
  <div className={className || 'mt-6'}>
    <div
      className={`inline-flex items-center divide-x ${
        separatorColor || 'divide-gray-300'
      }`}
    >
      <div className="flex-shrink-0 flex pr-5">
        {[...Array(5)].map(() => (
          <svg
            className="h-5 w-5 text-yellow-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        ))}
      </div>
      <div className="min-w-0 flex-1 pl-5 py-1 text-gray-500 sm:py-2">
        <span
          className={`font-medium ${
            inverse ? 'text-gray-300' : 'text-gray-500'
          }`}
        >
          5.0 stars
        </span>{' '}
        <a
          className="inline-block ml-3"
          href="https://apps.shopify.com/google-tag-manager-installer#reviews"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span
            className={`font-medium ${
              linkColor || 'text-blue-500 hover:text-blue-700'
            }`}
          >
            All reviews &rarr;
          </span>
        </a>
      </div>
    </div>
  </div>
);

export default StarRatings;
